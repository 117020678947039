// @flow
import { connect } from 'react-redux';

import { displayModal } from 'actions/modalActions';
import type { StateProps, DispatchProps } from 'components/billboard/BillboardBanner';
import BillboardBanner from 'components/billboard/BillboardBanner';
import type { StateType, DispatchType } from 'types/Actions';

export default (connect(
	(state: StateType): $Exact<StateProps> => ({
		club: state.clubState.club,
		email: state.clubState.club.email,
		magicLink: state.magicLinkState.magicLink,
	}),
	(dispatch: DispatchType): $Exact<DispatchProps> => ({
		displayModal: (modalObject: string, content: any) => dispatch(displayModal(modalObject, content)),
	}),
)(BillboardBanner): any);
