// @flow
import Head from 'next/head';
import React, { PureComponent } from 'react';

export type StateProps = {
	description: string,
	google_verification: string,
	title: string,
};

type Props = StateProps;

class BillboardHead extends PureComponent<Props> {
	render(): React$Element<React$FragmentType> {
		const { description, google_verification, title } = this.props;
		// Head
		const headTitle = title ?? 'Fédération Française de Rugby - FFR';
		const headDescription =
			description ??
			`Retrouvez toute l'actualité et les résultats
  des équipes de France de rugby et du rugby amateur
  sur le site de la Fédération Française de Rugby`;
		return (
			<>
				<Head>
					{/* https://developers.google.com/search/docs/advanced/crawling/special-tags?hl=fr
				que sur la page de 1er niveau */}
					{google_verification && <meta name="google-site-verification" content={google_verification} />}
					<title>{title}</title>
					<meta name="description" content={description} />
					<meta property="og:title" content={title} />
					<meta property="og:description" content={description} />
					<meta
						property="og:image"
						content="https://api.www.ffr.fr/wp-content/uploads/2019/02/Portail-FFR-Entre%CC%81e.jpg"
					/>
					<meta
						property="og:image:url"
						content="https://api.www.ffr.fr/wp-content/uploads/2019/02/Portail-FFR-Entre%CC%81e.jpg"
					/>
				</Head>
			</>
		);
	}
}

export default BillboardHead;
