// @flow

import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { getCurrentPageSeoConstant } from 'constants/seoConstants';

function CustomHead(): null | React$Element<any> {
	const router = useRouter();

	const currentPageSeoConstant = getCurrentPageSeoConstant(router.asPath);

	if (!currentPageSeoConstant) {
		return null;
	}

	return <Head>{!!currentPageSeoConstant.title && <title>{currentPageSeoConstant.title}</title>}</Head>;
}

export default CustomHead;
