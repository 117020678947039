// @flow

import { END } from 'redux-saga';

import { fetchClub } from 'actions/clubActions';
import { stockMagicLink, stockStructureID } from 'actions/magicLinkActions';
import Billboard from 'components/billboard';
import wrapper from '../store';
import type { DispatchType, StateType } from 'types/Actions';
import { getInitialData } from 'utils/nextUtils';
import { getUserData } from 'utils/magicLinkUtils';

export default Billboard;

export const getServerSideProps: any = wrapper.getServerSideProps((store) => async (ctx) => {
	// data communes
	getInitialData(store);

	// gestion du magicLink
	const { structureID, magicLink } = getUserData(ctx.query);
	const id = parseInt(structureID, 10);
	store.dispatch(stockStructureID(id));
	store.dispatch(stockMagicLink(magicLink));
	// if (!isNaN(id) && id > 0 && magicLink) {
	// 	store.dispatch(fetchClub(id, magicLink));
	// }

	store.dispatch(END);
	await store.sagaTask.toPromise();
});
