// @flow

import React, { PureComponent } from 'react';
import { withRouter } from 'next/router';

import withScreenType from 'components/fragments/withScreenType';
import type { HocStateProps } from 'components/fragments/withScreenType';
import type { ClubType } from 'types/Club';
import type { RouterProps } from 'types/Router';
import { getPublicAssets } from 'utils/assetsUtils';
import TagManager from 'utils/tagManagerUtils';

export type StateProps = {
	club: ClubType,
	email: string,
	magicLink: string,
};

export type DispatchProps = {
	displayModal: (modalObject: string, content: any) => void,
};

type Props = DispatchProps & HocStateProps & RouterProps & StateProps;

class BillboardBanner extends PureComponent<Props> {
	openCreationRequestModal = () => {
		const { displayModal, router, magicLink, email } = this.props;

		TagManager.handleTagEvent({
			event: 'cta_clicked',
			cta_position: 'milieu',
			cta_wording: 'Personnaliser mes kits',
		});

		email ? displayModal('RE_ASK_MAGIC_LINK', { title: `Accéder à mes kits` }) : displayModal('CREATION_REQUEST');
	};

	render() {
		const { club, email, isSmall, magicLink } = this.props;
		const clubName = club.nom;
		const banner = isSmall ? '/assets/img/banner/kits-comm-small.jpg' : '/assets/img/banner/kits-comm.jpg';

		return (
			<section className="banner banner--site-club banner--site-club-index">
				<div className="banner__thumbnail" style={{ backgroundImage: `url(${getPublicAssets(banner)})` }} />
				<div className={`container ${isSmall ? 'isSmall' : ''}`}>
					<div className="row">
						<div className="col col-md-6 banner__pres">
							<div>
								<h1 className="mb-4 ft-h2">
									Des kits de communication prêts à l’emploi et personnalisables pour votre communication
								</h1>
								<div>
									<p>
										Téléchargez et créez des kits pour toutes vos communications en quelques clics, grâce à la nouvelle
										plateforme dédiée de la FFR. Pour une communication simple, rapide, gratuite et autonome.
									</p>
									{!magicLink || magicLink === '' ? (
										<button type="button" className="btn btn--primary" onClick={this.openCreationRequestModal}>
											{'Personnaliser mes kits'}
										</button>
									) : (
										<a
											type="button"
											className="btn btn--primary"
											href={club.configuration.step === null && isSmall ? '/initialisation' : '/kits/tableau-de-bord'}>
											{'Accéder à mes kits'}
										</a>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default (withRouter(withScreenType(BillboardBanner)): any);
