// @flow
import React, { PureComponent } from 'react';

import BillboardBanner from 'containers/billboard/BillboardBannerContainer';
import BillboardHead from 'containers/billboard/HeadContainer';
import BillboardPage from 'containers/billboard/BillboardPageContainer';

type Props = {};

class Billboard extends PureComponent<Props> {
	render(): React$Element<React$FragmentType> {
		return (
			<>
				<BillboardHead />
				<BillboardBanner />
				<BillboardPage />
			</>
		);
	}
}

export default Billboard;
