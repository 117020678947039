// @flow
import { connect } from 'react-redux';

import BillboardHead from 'components/billboard/Head';
import type { StateProps } from 'components/billboard/Head';
import type { StateType } from 'types/Actions';

const mapStateToProps = (state: StateType): $Exact<StateProps> => ({
	description: state.settingsState.description,
	google_verification: state.settingsState.google_verification,
	title: state.settingsState.title,
});

const mapDispatchToProps = {};

export default (connect(mapStateToProps, mapDispatchToProps)(BillboardHead): any);
