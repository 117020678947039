/* eslint-disable @next/next/no-img-element */
// @flow
import { withRouter } from 'next/router';
import React, { PureComponent } from 'react';

import withScreenType from 'components/fragments/withScreenType';
import CustomHead from 'components/customHead';
import type { HocStateProps } from 'components/fragments/withScreenType';
import type { ClubType } from 'types/Club';
import type { RouterProps } from 'types/Router';
import { getPublicAssets } from 'utils/assetsUtils';
import TagManager from 'utils/tagManagerUtils';

export type StateProps = {
	club: ClubType,
	email: string,
	magicLink: string,
};

export type DispatchProps = {
	displayModal: (modalObject: string) => void,
};

type Props = DispatchProps & HocStateProps & RouterProps & StateProps;

class BillboardPage extends PureComponent<Props> {
	openCreationRequestModal = () => {
		const { displayModal, email, magicLink, router } = this.props;

		TagManager.handleTagEvent({
			event: 'cta_clicked',
			cta_position: 'bas',
			cta_wording: 'Personnaliser mes kits',
		});

		email ? displayModal('RE_ASK_MAGIC_LINK') : displayModal('CREATION_REQUEST');
	};

	renderLinkButton() {
		const { club, email, isMobile, isSmall, magicLink } = this.props;

		if (!magicLink || magicLink === '') {
			return (
				<button type="button" className="btn btn--primary mt-4" onClick={this.openCreationRequestModal}>
					{'Personnaliser mes kits'}
				</button>
			);
		} else {
			return (
				<a
					type="button"
					className="btn btn--primary mt-4"
					href={club.configuration.step === null || isSmall ? '/initialisation' : '/kits/tableau-de-bord'}>
					{'Accéder à mes kits'}
				</a>
			);
		}
	}

	render() {
		const { club, email, isMobile, isSmall, magicLink } = this.props;
		const banner = isSmall
			? '/assets/img/banner/kits-comm-2-small-sans-image.jpg'
			: '/assets/img/banner/kits-comm-2-sans-images.jpg';

		return (
			<>
				<CustomHead />
				<div className="page-site-club page-site-club--index mb-lg-6">
					<div className="container">
						<div className="site-club-container block-site-club block-site-club--index">
							<div className="block-site-club__content block-site-club__content--first">
								<div className={`container ${isSmall ? 'isSmall' : ''}`}>
									<div className="row">
										<div className="col col-md-3">
											<h2 className="mb-5">Un service pensé pour les clubs, les ligues et les comités</h2>
										</div>

										<div className="col col-md-3 block-site-club--services">
											<div>
												<img src={getPublicAssets('/assets/img/puzzle-pieces.png')} alt="thématisé" />
											</div>
											<h3>Thématisé</h3>
											<div>
												<p>
													Les kits de communication FFR sont pensés pour communiquer sur tous vos temps forts : matchs,
													événements, rassemblements... via des publications sur les réseaux sociaux, sites clubs, sites ligues, sites comités
													affichages, flyers...
												</p>
											</div>
										</div>

										<div className="col col-md-3 block-site-club--services">
											<div>
												<img src={getPublicAssets('/assets/img/cursors.png')} alt="personnalisation" />
											</div>
											<h3>Personnalisé</h3>
											<div>
												<p>
													Profitez des modèles pour personnaliser vos kits dans tous les formats. Ajoutez votre logo,
													vos partenaires, votre texte et informations clés ou encore vos couleurs.
												</p>
											</div>
										</div>

										<div className="col col-md-3 block-site-club--services">
											<div>
												<img src={getPublicAssets('/assets/img/euros.png')} alt="gratuit" />
											</div>
											<h3>Gratuit</h3>
											<div>
												<p>
													Le service kit de communication FFR vous est totalement offert par la Fédération Française de
													Rugby. La plateforme vous permet de créer vos outils de communication rapidement et facilement
													pour répondre à tous vos besoins.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="block-site-club__content block-site-club__content--second">
								<div
									className={`container ${isSmall ? 'isSmall' : ''}`}
									style={{ backgroundImage: `url(${getPublicAssets(banner)})` }}>
									<div className="row">
										{isMobile ? (
											<div className="col col-lg-6 site-club__content__img">
												<img
													src={getPublicAssets('/assets/img/banner/kits-comm-2-image-small.jpg')}
													alt="Kits comm club écran mobile"
												/>
											</div>
										) : (
											<>
												<div className="col col-lg-6 site-club__content__img">
													<div
														style={{
															backgroundImage: `url(${getPublicAssets('/assets/img/banner/kits-comm-2.png')})`,
														}}></div>
												</div>
											</>
										)}
										<div className="col col-lg-6">
											<div>
												<h2 className="mb-2 mb-md-3">De nombreux modèles de kit pour toutes les occasions</h2>
												<p>
													La Fédération Française de Rugby vous a préparé de nombreux modèles de kits différents,
													adaptés aux thématiques quotidiennes de la vie d’un club, d’une ligue ou d’un comité. Vous aurez le choix entre :
												</p>
												<ul>
													<li>Des kits prêts à l’emploi téléchargeables directement</li>
													<li>Des modèles de kits personnalisables</li>
												</ul>
												{this.renderLinkButton()}
											</div>
										</div>
									</div>
								</div>
							</div>

							{/*
						<div className="block-site-club__content block-site-club__content--first">
							<div className={`container ${isSmall ? 'isSmall' : ''}`}>
								<div className="row">
									<div className="col col-md-6 col-lg-5 col-lg-offset-1on9">
										<h2 className="mb-5">Un besoin particulier ? Demandez votre kit sur-mesure</h2>
										<p>
											Vous avez besoin de communiquer sur un événement qui n’est pas couvert par les modèles de kit ?
											Envoyez votre demande à la FFR via un court formulaire et obtenez votre kit de communication
											sur-mesure, confectionné par les services de la FFR.
										</p>
									</div>
									<div className="col col-md-6 col-lg-5 site-club__content__img mt-5 mt-md-0">
										<div
											style={{
												backgroundImage: `url(${getPublicAssets('/assets/img/banner/kits-comm-3.jpg')})`,
											}}>
											{this.renderLinkButton()}
										</div>
									</div>
								</div>
							</div>
						</div>*/}

							<div
								className="block-site-club__header"
								style={{ backgroundImage: `url(${getPublicAssets('/assets/img/bg-site-club-index.jpg')})` }}>
								<div className="info-bubble">
									<div className="info-bubble__widget">
										<span className="badge">
											<i className="icon icon-coment-fb" />
											<i className="icon icon-close" />
										</span>
									</div>
								</div>
								<div className={`container ${isSmall ? 'isSmall' : ''}`}>
									<div className="row">
										<div className="col">
											<h3 className="mt-4 mt-md-5 mb-1">Vous n’êtes pas seul !</h3>
											<p className="mb-4 mb-md-6">
												Pendant la configuration, vous aurez la possibilité d’avoir des conseils ou des réponses à vos
												interrogations grâce à notre assistance en ligne.
												{/* Vous pouvez également retrouver la réponse à vos questions dans <a href="#">notre FAQ</a> // ! @todo : lien vers la faq
												 */}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default (withRouter(withScreenType(BillboardPage)): any);
